// app/javascript/controllers/menu_hover_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["categories"];

  // Show categories and subcategories on hover
  showCategories() {
    this.categoriesTarget.style.display = "block";
  }

  // Hide categories and subcategories when mouse leaves
  hideCategories() {
    this.categoriesTarget.style.display = "none";
  }

  // Use event listeners for hover interactions
  initialize() {
    this.element.addEventListener("mouseenter", () => this.showCategories());
    this.element.addEventListener("mouseleave", () => this.hideCategories());
    console.log('Initialization complete');
  }
}